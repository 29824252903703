@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700")

$nobel-yellow = #ecae1b
$nobel-blue = #1f365e
$container-size = 1200px

*
	box-sizing border-box
html, body
	width 100%
	height 100%
	margin 0
	padding 0
	font-family 'Roboto', sans-serif

body
	display block
	// background-image url(/images/image.jpg)

.container
	max-width $container-size
	margin 0px auto

.flex
	display flex
	
	&.vertical
		flex-direction column
	&.justify-center
		justify-content center
	&.align-center
		align-items center
	&.center-all
		justify-content center
		align-items center
	&.wrap
		flex-wrap wrap

.nobel-leuk-man
	width 100%
	height 100%
	display flex
	justify-content center
	align-items center
	position relative
	z-index 10
	overflow hidden

	video
		display block
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		width 100%
		height 100%
		object-fit cover

	.overlay
		display block
		position absolute
		top 0
		right 0
		bottom 0
		left 0
		width 100%
		height 100%
		background $nobel-blue
		opacity 0.5

	.center
		padding 20px
		display flex
		position relative
		z-index 100
		justify-content center
		align-items center

		img
			max-width 100%
			max-width 353px
			margin-right 40px

		h1
			font-family Georgia,serif
			font-weight normal
			font-style italic
			font-size 64px
			text-align center
			color $nobel-yellow

		@media (max-width 800px)
			flex-direction column

			img
				margin-right 0
				margin-bottom 20px
			h1
				font-size 32px

.image-section
	position relative
	z-index 8000
	background-size cover
	background-position center
	height 40vh
	display flex
	justify-content center
	align-items center

	span
		font-family Georgia,serif
		font-weight normal
		font-style italic
		font-size 64px
		text-align center
		color $nobel-blue

section
	padding 40px
	background $nobel-blue
	color $nobel-yellow
	position relative
	z-index 9000
	box-shadow 0 0 32px 4px rgba(0, 0, 0, 0.2)

	&:nth-child(1)
		z-index 9010

	&:nth-child(2)
		z-index 9020

	&:nth-child(3)
		z-index 9030

	&.yellow
		background $nobel-yellow
		color $nobel-blue

	h2
		margin 0
		font-size 48px
		font-family Georgia, 'Times New Roman', Times, serif
		font-weight normal
		text-align center

		span
			padding 10px 40px
			margin 0 auto
			display inline-block

	h4
		font-size 24px
		font-family Georgia, 'Times New Roman', Times, serif
		font-weight normal
		text-align center
		margin-top -5px
		color darken($nobel-yellow, 10%)

	p
		margin-top 30px
		font-size 18px
		line-height 1.4

		span
			display block
			float left
			line-height normal
			font-size 64px
			margin-top -9px
			margin-bottom -9px
			margin-right 10px
			font-family Georgia, 'Times New Roman', Times, serif

	button
		font-family inherit
		font-size inherit
		font-weight inherit
		color inherit
		background inherit
		border inherit
		outline inherit

		&:hover, &:focus
			outline inherit

.nobel-is-leuk
	position relative
	z-index 9000
	height 100vh
	background black
	padding 14px
	background-image url(/img/leuk3.jpg)
	background-size cover
	background-position center 20%

.nobel-is-leuk-echt
	width 100%
	height 80vh
	background $nobel-yellow
	color $nobel-blue
	display flex
	justify-content center
	align-items center
	position relative
	z-index 1000
	box-shadow 0 0 42px 1px $nobel-blue

	span
		font-size 4vw
		font-family Georgia, 'Times New Roman', Times, serif
		font-weight bold
		text-align center
